import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import { Button, Icon, Tooltip } from 'hudl-uniform-ui-components';
import tooltipPreviewData from '../../../data/previews/tooltip.yml';
import pageHeroData from '../../../data/pages/components.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      triggerType: 'icons',
      delayType: 'immediate',
    };
  }

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Tooltip"
        designCode
        subnav="components">
        <PageHero heroData={pageHeroData} tierTwo="Tooltip" />

        <PageNavigation>
          <PageNavigationLink>Triggers</PageNavigationLink>
          <PageNavigationLink>Position</PageNavigationLink>
          <PageNavigationLink>Delay</PageNavigationLink>
          <PageNavigationLink>Mobile</PageNavigationLink>
          <PageNavigationLink>Usage</PageNavigationLink>
          <PageNavigationLink>Microcopy</PageNavigationLink>
          <PageNavigationLink>Platform</PageNavigationLink>
        </PageNavigation>

        <Section title="Triggers">
          <ComponentPreview
            name="TooltipTrigger"
            layout="row"
            previewData={tooltipPreviewData.types}
            onChange={() => {
              this.setState({
                triggerType:
                  this.state.triggerType === 'icons' ? 'buttons' : 'icons',
              });
            }}>
            {this.state.triggerType === 'icons' && (
              <Tooltip>
                <Icon type="information" size="large" />
              </Tooltip>
            )}
            {this.state.triggerType === 'buttons' && (
              <Tooltip>
                <Button type="primary" size="large">
                  Share
                </Button>
              </Tooltip>
            )}
          </ComponentPreview>
        </Section>

        <Section title="Position">
          <Paragraph>
            The tooltip’s position is dictated by the interface design. Avoid
            hiding other key elements or getting cut off by an edge.
          </Paragraph>
          <ComponentPreview
            name="TooltipPosition"
            layout="row"
            previewData={tooltipPreviewData.position}>
            <Tooltip>
              <Icon type="information" size="large" />
            </Tooltip>
          </ComponentPreview>
        </Section>

        <Section title="Delay">
          <ComponentPreview
            name="TooltipDelay"
            layout="row"
            previewData={tooltipPreviewData.delay}
            onChange={() => {
              this.setState({
                delayType:
                  this.state.delayType === 'immediate'
                    ? 'delayed'
                    : 'immediate',
              });
            }}>
            <Tooltip
              delayTime={this.state.delayType === 'immediate' ? 0 : 1000}>
              <Icon type="information" size="large" />
            </Tooltip>
          </ComponentPreview>
        </Section>
        <Section title="Mobile">
          <Paragraph>
            Tooltips should not be used on mobile. Instead, use a{' '}
            <Link href="/components/notifications/note/design">note</Link> to
            explain what the user needs to know.{' '}
          </Paragraph>
        </Section>

        <Section title="Usage">
          <SectionSubhead>Hover Required</SectionSubhead>
          <Paragraph>
            The tooltip appears on hover by default, but should also be
            triggered on focus.{' '}
            <strong>Tooltips should never appear without a trigger,</strong>{' '}
            especially not as onboarding prompts.
          </Paragraph>

          <SectionSubhead>Type Treatments</SectionSubhead>
          <Paragraph>
            There’s a hard limit of <strong>one</strong> bold or italicized
            phrase—not complete sentence—per tooltip. Use it to call out another
            element (clips, playlists, etc.) or the desired action.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="bold an entire tooltip."
              img="tooltip-bold-dont"
            />
            <DontDoItem
              type="do"
              text="bold or italicize a single important piece of information."
              img="tooltip-bold-do"
            />
          </DontDo>

          <SectionSubhead>Metadata</SectionSubhead>
          <Paragraph>
            Don’t use a tooltip to display metadata, like viewing activity for a
            specific team member. The content should relate directly to the
            interface element, not provide new information with zero context.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="pack the tooltip with data they may not expect."
              img="tooltip-metadata-dont"
            />
            <DontDoItem
              type="do"
              text="center the content around the trigger itself."
              img="tooltip-metadata-do"
            />
          </DontDo>

          <SectionSubhead>On Links</SectionSubhead>
          <Paragraph>
            Because we{' '}
            <Link href="/components/link/design#Microcopy">
              link the action
            </Link>{' '}
            and/or where a link takes the user, there’s no need to include a
            tooltip where that same information would be shared.{' '}
            <strong>Avoid tooltips on links altogether.</strong>
          </Paragraph>

          <SectionSubhead>In Forms</SectionSubhead>
          <Paragraph>
            Tooltips should not replace{' '}
            <Link href="/components/forms/input/design/#Microcopy">
              help text
            </Link>{' '}
            on form inputs—the help text should communicate exactly what the
            user is submitting.
          </Paragraph>
          <Paragraph>
            Radios and checkboxes, however, can use tooltips to communicate what
            a user is <em>selecting</em>.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="use a tooltip in addition to help text."
              img="tooltip-forms-dont"
            />
            <DontDoItem
              type="do"
              text="help the user understand their decision."
              img="tooltip-forms-do"
            />
          </DontDo>

          <SectionSubhead>Disabled Buttons</SectionSubhead>
          <Paragraph>
            Use a tooltip to explain why the button is disabled and provide
            clear direction for moving forward.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="simply say that the button is disabled."
              img="tooltip-disabledbutton-dont"
            />
            <DontDoItem
              type="do"
              text="provide more context around what enables the button."
              img="tooltip-disabledbutton-do"
            />
          </DontDo>
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Content Length</SectionSubhead>
          <Paragraph>
            <strong>Tooltips should be no longer than 110 characters.</strong>{' '}
            That comes to four full lines for an effective message.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="ramble and take up a quarter of the interface for a quick FYI."
              img="tooltip-contentlength-dont"
            />
            <DontDoItem
              type="do"
              text="be as clear and concise as possible."
              img="tooltip-contentlength-do"
            />
          </DontDo>

          <SectionSubhead>Be Direct</SectionSubhead>
          <Paragraph>
            Tooltips don’t require fun phrases or a full shot of personality,
            just tell the user exactly what they need to know. <em>Inform</em>{' '}
            them and move on.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="go crazy with a casual tone and miss the point."
              img="tooltip-direct-dont"
            />
            <DontDoItem
              type="do"
              text="focus on the most necessary information."
              img="tooltip-direct-do"
            />
          </DontDo>

          <SectionSubhead>Linked Text</SectionSubhead>
          <Paragraph>
            A tooltip should never take the user somewhere else—all necessary
            information should live in the tooltip itself (for the sake of
            context more than anything). <strong>Don’t link the text</strong>.
          </Paragraph>

          <SectionSubhead>Punctuation</SectionSubhead>
          <Paragraph>
            <strong>Only periods are allowed in tooltips.</strong> We shouldn’t
            use the limited space to yell with an exclamation or ask questions
            with no answer.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="ask a question when we can't fit an answer."
              img="tooltip-punctuation-dont"
            />
            <DontDoItem
              type="do"
              text="use a period to instill confidence in the complete thought."
              img="tooltip-punctuation-do"
            />
          </DontDo>
        </Section>

        <Section title="Platform">
          <PlatformTable>
            <PlatformTableRow platform="web" />
            <PlatformTableRow platform="apple" />
            <PlatformTableRow platform="android" />
          </PlatformTable>
        </Section>
      </PageWithSubNav>
    );
  }
}
